<template>
  <v-col class="ma-3">
    <v-form ref="form" v-model="isValid" @submit.prevent="handleRegister" class="pt-10">
      <h1 class="text-h1 mt-10">
      {{ $t('authorisation.passwordReset.title') }}
    </h1>
      <v-alert :type="alertType" :value="!!alertMessage.length" class="my-5" transition="fade-transition">
        {{ alertMessage }}
      </v-alert>
      <k-field-group language-prefix="authorisation.fields" class="mt-4 mb-2">
        <KTextField v-model="form.email" field="email" tabindex="1"/>
        <KTextField v-model="form.password"
                    :append-icon="showPassword ? '$invisible' : '$visible'"
                    :type="showPassword ? 'text' : 'password'"
                    field="password"
                    tabindex="2"
                    @click:append="showPassword = !showPassword"/>
        <KTextField v-model="form.passwordConfirmation"
                    :append-icon="showPassword ? '$invisible' : '$visible'"
                    :type="showPassword ? 'text' : 'password'"
                    field="password"
                    tabindex="3"
                    @click:append="showPassword = !showPassword"/>
      </k-field-group>
      <v-row class="mx-0 my-6" justify="end">
        <VBtn :loading="isLoading" depressed tile color="secondary" tabindex="4" type="submit" v-text="$t('actions.save')"/>
      </v-row>
    </v-form>
  </v-col>
</template>

<script lang="js">
import KTextField from '@/components/crud/fields/KTextField.vue';
import KFieldGroup from '@/components/crud/fields/KFieldGroup.vue';
import resetRequest from '@/modules/authorisation/api/reset.js';
import { getRateLimitMinutes } from '@/application/api/util/response.js';

export default {
  name: 'PasswordReset',
  components: {
    KTextField,
    KFieldGroup,
  },
  data() {
    return {
      alertType: 'success',
      alertMessage: '',
      isLoading: false,
      isValid: false,
      form: {
        email: '',
        password: '',
        passwordConfirmation: '',
      },
      showPassword: false,
    };
  },
  created() {
    this.form.email = this.$route.query.email;
  },
  methods: {
    handleRegister() {
      this.$refs.form.validate();

      if (!this.isValid) return;

      this.isLoading = true;
      this.alertType = 'error';
      this.errorMessage = '';

      resetRequest(this.form.email, this.$route.params.token, this.form.password, this.form.passwordConfirmation)
          .then(() => {
            this.alertType = 'success';
            this.alertMessage = this.$t('authorisation.passwordReset.successMessage');
          })
          .catch((error) => {
            this.alertType = 'error';
            const { response } = error;
            const { status } = response;

            if (status === 429) {
              this.alertMessage = this.$t('errors.429', { minutes: getRateLimitMinutes(response) });
            } else if (status === 400) {
              this.alertMessage = this.$t('authorisation.passwordReset.errorMessage');
            }

            this.$refs.form.validate();
          })
          .finally(() => {
            this.isLoading = false;
          });
    },
  },
};
</script>

import { post } from '@/application/api/implementations/app';

export default async function (email, token, password, passwordConfirmation, organisation) {
  return post('password/reset', {
    email,
    token,
    password,
    password_confirmation: passwordConfirmation,
    organisation,
  });
}
